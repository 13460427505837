import { Dispatch } from 'redux'
import { getType } from 'typesafe-actions'
import { authGetUserAction } from '../actions/authenticationActions'
import {
  signalRConnectedAction,
  signalRConnectingAction,
  signalRDisconnectedAction,
} from '../actions/signalRActions'
import { buildApiUrl } from '../services/api'
import { AppState } from '../store'
import { CchResponseMessageListener } from './cchResponseMessageListener'
import { listeners as FileListeners } from './fileSignalR'
import { listeners as K1Listeners } from './k1SignalR'
import { listeners as DuoStatusListener } from './duoStatusSignalR'
import { listeners as EtltoAnswerListeners } from './etlToAnswerSignalR'
import {
  listeners as MessageListeners,
  reducers as MessageReducers,
} from './messageSignalR'
import { SignalRConfiguration } from './middleware'

function handleConnected(_: () => AppState, dispatch: Dispatch<any>) {
  dispatch(signalRConnectedAction())
}

function handleConnecting(_: () => AppState, dispatch: Dispatch<any>) {
  dispatch(signalRConnectingAction())
}

function handleDisconnected(_: () => AppState, dispatch: Dispatch<any>) {
  dispatch(signalRDisconnectedAction())
}

export const Configuration: SignalRConfiguration = {
  hubUrl: buildApiUrl('Hub'),
  reducers: [...MessageReducers],
  listeners: [
    ...MessageListeners,
    ...FileListeners,
    ...K1Listeners,
    ...EtltoAnswerListeners,
    ...DuoStatusListener,
    CchResponseMessageListener,
  ],
  authentication: {
    actionType: getType(authGetUserAction),
    getBearerToken: function(action: any) {
      if (action && action.payload) {
        return action.payload.accessToken
      }
      return ''
    },
  },
  onConnected: handleConnected,
  onConnecting: handleConnecting,
  onDisconnected: handleDisconnected,
}
