import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { createSelector } from 'reselect'
import { actions } from '../../actions'
import {
  AsyncOperationMap,
  Engagement,
  EngagementReportRequestResponse,
  EngagementReportType,
  File as TsaFile,
  FileGroupMap,
  IdentityTokenProfile,
} from '../../clientModels'
import { DocumentTitleId, PhaseCode, UserPermissions } from '../../enums'
import { ensureNumber } from '../../guards'
import {
  selectEngagementFileGroups,
  getEngagementByEngagementId,
} from '../../reducers/selectors'
import { Abort } from '../../services/abort'
import { hasFileGroup } from '../../services/fileGroupHelpers'
import { Guid } from '../../services/guid'
import { safeJsonParse } from '../../services/jsonHelpers'
import { getNewAbort, RequestAbort } from '../../services/lastRequests'
import { AppState, TsaDispatch } from '../../store'
import { Anchor } from '../anchor'
import { ReportDialog } from '../modals/index'
import {
  EngagementReportItem,
  EngagementReportItemProps,
} from './engagementReportItem'
import './engagementReports.scss'
import { hasPermission } from '../../services/permissionManagerHelpers'
import DuoExportDialog from '../modals/duoExportDialog'

interface RouteParams {
  engagementId: string
}

interface EngagementReportsOwnProps extends RouteComponentProps<RouteParams> {}

interface EngagementReportsMappedProps {
  engagement?: Engagement
  asyncOperations: AsyncOperationMap
  fileGroups?: FileGroupMap
  isExternalUser: boolean
  user: IdentityTokenProfile | undefined
  permissions: string[]
}

interface EngagementReportsDispatchProps {
  addWatch: (asyncOperationId: number) => void
  downloadFile: (fileId: number) => void
  removeWatch: (asyncOperationId: number) => void
  requestReport: (
    reportType: EngagementReportType,
    token: string,
    abort: Abort
  ) => Promise<EngagementReportRequestResponse>
  submitDuoExportData: (fileType: string) => void
}

type EngagementReportsProps = EngagementReportsOwnProps &
  EngagementReportsMappedProps &
  EngagementReportsDispatchProps

interface EngagementReportsState {
  asyncOperationId?: number
  reportType?: EngagementReportType
  showRequestDialog: boolean
  showExportDialog: boolean
  token?: string
  requestAbort: RequestAbort
  duoFileType: string
  isDuoCsvExportTriggered: boolean
  isDuoXmlExportTriggered: boolean
}

const selectFileGroupMap = createSelector(
  (state: AppState) => state.fileGroups,
  (_: any, props: EngagementReportsOwnProps) => props.match.params.engagementId,
  selectEngagementFileGroups
)

const mapStateToProps = (
  state: AppState,
  props: EngagementReportsOwnProps
): EngagementReportsMappedProps => {
  const { async, auth } = state
  return {
    engagement: getEngagementByEngagementId(
      state,
      props.match.params.engagementId
    ),
    asyncOperations: async.operations,
    fileGroups: selectFileGroupMap(state, props),
    isExternalUser: !!auth.user && auth.user.isExternal,
    user: auth.user,
    permissions: state.permissions.permissions,
  }
}

const mapDispatchToProps = (
  dispatch: TsaDispatch,
  props: EngagementReportsOwnProps
): EngagementReportsDispatchProps => {
  const { engagementId } = props.match.params

  return {
    addWatch: asyncOperationId =>
      dispatch(actions.async.addWatch(asyncOperationId)),
    downloadFile: fileId => dispatch(actions.file.downloadFile(fileId)),
    removeWatch: asyncOperationId =>
      dispatch(actions.async.removeWatch(asyncOperationId)),
    requestReport: (reportType, token, abort) =>
      dispatch(
        actions.engagementReport.requestReport(
          ensureNumber(engagementId),
          reportType,
          token,
          abort
        )
      ),
    submitDuoExportData: fileType =>
      dispatch(
        actions.duoExport.submitDuoExportData(
          ensureNumber(engagementId),
          fileType
        )
      ),
  }
}

export class EngagementReports extends React.Component<
  EngagementReportsProps,
  EngagementReportsState
> {
  state: EngagementReportsState = {
    showRequestDialog: false,
    showExportDialog: false,
    requestAbort: getNewAbort(),
    duoFileType: '',
    isDuoCsvExportTriggered: false,
    isDuoXmlExportTriggered: false,
  }

  getAsyncOperation = () => {
    const {
      props: { asyncOperations },
      state: { asyncOperationId },
    } = this
    return asyncOperationId ? asyncOperations[asyncOperationId] : undefined
  }

  getReportTitle = () => {
    const { reportType } = this.state

    switch (reportType) {
      case 'audit':
        return 'Audit Trail Report'
      case 'pbc':
        return 'My Information'
      default:
        return 'Report'
    }
  }

  closeModal = () => {
    const {
      props: { removeWatch },
      state: { asyncOperationId, requestAbort },
    } = this
    if (asyncOperationId) {
      // Stop polling for status updates on current async operation
      removeWatch(asyncOperationId)
    } else {
      // Abort request for report -- this reduces the amount of error dialogs the user
      // will see if cancelling out of the download during a long-running request
      requestAbort.abort()
    }

    this.setState({
      asyncOperationId: undefined,
      reportType: undefined,
      showRequestDialog: false,
      showExportDialog: false,
      token: undefined,
      duoFileType: '',
    })
  }

  downloadReport = () => {
    const { downloadFile } = this.props
    const asyncOperation = this.getAsyncOperation()

    if (asyncOperation) {
      const propertyBag = safeJsonParse(asyncOperation.propertyBag)
      if (propertyBag.FileId) {
        downloadFile(propertyBag.FileId)
      }
    }

    this.closeModal()
  }

  submitExportToDuo = () => {
    const {
      props: { submitDuoExportData },
      state: { duoFileType },
    } = this

    if (duoFileType === 'Csv') {
      this.setState({
        isDuoCsvExportTriggered: true,
      })
    } else {
      this.setState({
        isDuoXmlExportTriggered: true,
      })
    }

    submitDuoExportData(duoFileType)

    this.closeModal()
  }

  handleRequestResponse = (response: EngagementReportRequestResponse) => {
    const {
      props: { addWatch },
      state: { token },
    } = this
    if (token === response.token) {
      if (response.asyncOperationId) {
        // Start polling for status updates on current async operation
        addWatch(response.asyncOperationId)
        this.setState({
          asyncOperationId: response.asyncOperationId,
          token: undefined,
        })
      } else {
        this.closeModal()
      }
    }
  }

  requestReportByType = (reportType: EngagementReportType) => {
    const {
      props: { requestReport },
      state: { requestAbort },
    } = this
    const newToken = Guid.newGuid()

    requestReport(reportType, newToken, requestAbort.new()).then(
      this.handleRequestResponse
    )

    this.setState({
      asyncOperationId: undefined,
      reportType,
      showRequestDialog: true,
      showExportDialog: false,
      token: newToken,
      duoFileType: '',
    })
  }

  exportDataToDuoByFileType = (
    reportType: EngagementReportType,
    duoFileType: string
  ) => {
    const newToken = Guid.newGuid()

    this.setState({
      asyncOperationId: undefined,
      reportType,
      showRequestDialog: false,
      showExportDialog: true,
      token: newToken,
      duoFileType: duoFileType,
    })
  }

  requestAuditReport = () => {
    this.requestReportByType('audit')
  }

  requestPbcReport = () => {
    this.requestReportByType('pbc')
  }

  requestK1Report = () => {
    this.requestReportByType('k1summary')
  }

  requestEngagementDataReport = () => {
    this.requestReportByType('engagementData')
  }

  exportEngagementDataCsvToDuo = () => {
    this.exportDataToDuoByFileType('duoExport', 'Csv')
  }

  exportEngagementDataXmlToDuo = () => {
    this.exportDataToDuoByFileType('duoExport', 'Xml')
  }

  requestPriorYearCarryForwardReport = (fileId: number) => {
    this.props.downloadFile(fileId)
  }

  getPbcReportDescription = (file: TsaFile) => {
    const createdDate =
      file.createdDate &&
      moment
        .utc(file.createdDate)
        .local()
        .format('L')
    return `${file.name} Created on: ${createdDate}`
  }

  render() {
    const {
      props: { downloadFile, fileGroups, isExternalUser, engagement },
      state: {
        showRequestDialog,
        showExportDialog,
        isDuoCsvExportTriggered,
        isDuoXmlExportTriggered,
      },
    } = this

    const asyncOperation = this.getAsyncOperation()
    const reportTitle = this.getReportTitle()
    const reportGroups = fileGroups && [
      ...(fileGroups.undefined || []),
      ...(fileGroups.null || []),
    ]
    const pbcReportFileGroup =
      reportGroups &&
      reportGroups.find(
        x => x.documentTitleId === DocumentTitleId.MyInformationProvided
      )
    const pbcReportReviewFile =
      pbcReportFileGroup &&
      pbcReportFileGroup.files &&
      pbcReportFileGroup.files.find(x =>
        x.tags ? x.tags.some(y => y.tag === 'pbc-review') : false
      )
    const reports: EngagementReportItemProps[] = [
      {
        buttonText: 'My Information Provided (PDF)',
        description: 'Download a report containing all information provided.',
        disabled: showRequestDialog,
        onClick: this.requestPbcReport,
        engagementid: engagement?.id || 0,
        reportType: 'myinformation',
      },
    ]

    if (
      hasPermission(
        this.props.user,
        this.props.permissions,
        UserPermissions.AuditTrailReportCanView
      )
    ) {
      //add this to the beginging of the array to preserver the order
      reports.unshift({
        buttonText: 'AUDIT TRAIL (PDF)',
        description: 'Download the latest audit trail report for this client.',
        disabled: showRequestDialog,
        hidden: isExternalUser,
        onClick: this.requestAuditReport,
        engagementid: engagement?.id || 0,
        reportType: 'audit',
      })
    }

    // We do not want to display the link for the PBCReview report during these phases
    const phases = [PhaseCode.Setup, PhaseCode.ClientValidation, PhaseCode.PBC]
    const displayPBCReportReviewFile =
      engagement?.phase && !phases.find(x => x === engagement?.phase)

    const hasK1FileGroup = hasFileGroup(
      DocumentTitleId.K1Document,
      fileGroups ?? {}
    )

    if (
      hasK1FileGroup &&
      hasPermission(
        this.props.user,
        this.props.permissions,
        UserPermissions.AuditTrailReportCanView
      )
    ) {
      reports.unshift({
        buttonText: 'Inbound K-1 Summary',
        description:
          'Download the latest inbound K-1 summary report for this client.',
        disabled: showRequestDialog,
        onClick: this.requestK1Report,
        engagementid: engagement?.id || 0,
        reportType: 'k1summary',
      })
    }

    if (fileGroups) {
      for (let key in fileGroups) {
        const fileGroupsList = fileGroups[key]
        if (fileGroupsList) {
          for (let fileGroup of fileGroupsList) {
            if (
              fileGroup?.files &&
              fileGroup.documentTitleId ===
                DocumentTitleId.PriorYearCarryForward
            ) {
              const fileId = fileGroup.files[0].id
              reports.push({
                buttonText: 'Prior Year Carry Forward (ZIP)',
                description:
                  "Download a file containing last year's carry forward information.",
                disabled: showRequestDialog,
                hidden: isExternalUser,
                onClick: () => this.requestPriorYearCarryForwardReport(fileId),
                engagementid: engagement?.id || 0,
                reportType: 'pbc',
              })
              break
            }
          }
        }
      }
    }

    reports.push({
      buttonText: 'Export Engagement (CSV)',
      description:
        'Download the latest engagement data report for this client.',
      hidden: isExternalUser,
      onClick: this.requestEngagementDataReport,
      engagementid: engagement?.id || 0,
      reportType: 'engagementData',
    })

    //DUO_COMMENT
    // reports.push({
    //   buttonText: 'DUO Export (CSV)',
    //   description: 'Export the latest engagement data to DUO.',
    //   hidden: isExternalUser,
    //   onClick: this.exportEngagementDataCsvToDuo,
    //   engagementid: engagement?.id || 0,
    //   reportType: 'duoExport',
    //   duoFileType: 'Csv',
    //   disabled: isDuoCsvExportTriggered,
    // })

    // reports.push({
    //   buttonText: 'DUO Export (XML)',
    //   description: 'Export the latest engagement data to DUO.',
    //   hidden: isExternalUser,
    //   onClick: this.exportEngagementDataXmlToDuo,
    //   engagementid: engagement?.id || 0,
    //   reportType: 'duoExport',
    //   duoFileType: 'Xml',
    //   disabled: isDuoXmlExportTriggered,
    // })

    return (
      <div className='engagement-reports'>
        {reports.map((r, i) => (
          <EngagementReportItem key={i} {...r} />
        ))}
        {displayPBCReportReviewFile && pbcReportReviewFile && (
          <div>
            <div>
              Access the report that was automatically generated for you when
              the engagement was submitted for review.
            </div>
            <Anchor onClick={() => downloadFile(pbcReportReviewFile.id)}>
              {this.getPbcReportDescription(pbcReportReviewFile)}
            </Anchor>
          </div>
        )}
        {showRequestDialog && (
          <ReportDialog
            downloadReady={
              !!asyncOperation && asyncOperation.status === 'Complete'
            }
            isError={!!asyncOperation && asyncOperation.status === 'Errored'}
            loadingTitle={`${reportTitle} Requested`}
            onClose={this.closeModal}
            onDownload={this.downloadReport}
            readyTitle={`${reportTitle} Ready for Download`}
            errorTitle={`${reportTitle} has errored out.`}
          />
        )}
        {showExportDialog && (
          <DuoExportDialog
            onClose={this.closeModal}
            onSubmit={this.submitExportToDuo}
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EngagementReports)
